import {navigate} from 'gatsby';
import React, {useEffect} from 'react';
import Layout from '../../components/layout/layout';

const Pricing = () => {
  useEffect(() => {
    navigate('/pricing/');
  }, []);

  return <Layout></Layout>;
};

export default Pricing;
